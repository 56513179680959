import * as Yup from "yup";

export const initialValues = {
  polingStation: "",
  Assembly: "",
  selectedDate: null,
  forestOffName: "",
  designation: "",
  mobileNum: "",
  files: [] as File[],
};

export const yupSchema = Yup.object({
  polingStation: Yup.string().required("Poling Station is required"),
  Assembly: Yup.string().required("Assembly is required"),
  selectedDate: Yup.date().nullable().required("Date is required"),
  forestOffName: Yup.string().required("Forest Officer Name is required"),
  designation: Yup.string().required("Designation is required"),
  mobileNum: Yup.string()
    .required("Mobile Number is required")
    .matches(
      /^[6789][0-9]{9}$/,
      "Mobile Number must be 10 digits long and should start with 6, 7, 8, or 9"
    ),

  files: Yup.array()
    .of(Yup.mixed().required("Photos are  required"))
    .max(3, "You can upload up to 3 Photos")
    .min(1, "You can upload up to 3 Photos"),
});

export const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
