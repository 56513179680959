import axios from "axios";

export const addElectionDetails = async (data: any) => {
  const url = "/API/election/addDetails";

  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Error making POST request:", error);
  }
};


