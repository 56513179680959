import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "gray !important",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent", // Make background transparent
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray", // Hide the red border
      color: "gray !important",
     

    },
    "& .MuiOutlinedInput-input": {
      backgroundColor: "transparent", // Ensure the input background is transparent
    },
  },
  "& .MuiFormHelperText-root": {
    color: "rgb(211, 47, 47)", // Customize the helper text color
  },
}));

// Visually hidden input for file upload
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray",
      color: "gray !important",
    },
    "&:hover fieldset": {
      borderColor: "gray",
      color: "gray !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "gray",
      color: "gray !important",
    },
  },
}));

export const ErrorStyled = styled("span")(({ theme }) => ({
  color: "#d32f2f",
  fontSize: "0.80rem",
  padding: "0px 12px",
}));
