import React from 'react';
import Alert from '@mui/material/Alert';
import LocationOn from '@mui/icons-material/LocationOn';

interface Props {
  location: number; // Status code or indicator for location
}

const LocationAlert: React.FC<Props> = ({ location }) => {
  return (
    <>
      {location === 0 && (
        <span
          style={{
            color: "#d32f2f",
            fontSize: "0.85rem",
          }}
        >
          <Alert
            icon={<LocationOn fontSize="inherit" />}
            severity="info"
            style={{ padding: "0px", margin: "3px 0px 0px 0px" }}
          >
            Kindly enable your location
          </Alert>
        </span>
      )}
    </>
  );
}

export default LocationAlert;
