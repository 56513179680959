import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Grid } from '@mui/material';

// Define the interface for props
interface CustomTextFieldProps
  extends Omit<TextFieldProps, 'variant' | 'size' | 'InputProps'> {
  label: string;
  name: string;
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  helperText?: React.ReactNode;
  disabled?: boolean;
  InputPropsStyle?: React.CSSProperties;
  variant?: 'outlined' | 'filled' | 'standard'; // Specify allowed variants
  size?: 'small' | 'medium'; // Specify allowed sizes
  fullWidth?: boolean;
  shrink?: boolean; // Optional boolean prop for shrinking label
}

const CustomDisabledTextField: React.FC<CustomTextFieldProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  disabled = false,
  variant = 'filled',
  size = 'small',
  InputPropsStyle = {},
  fullWidth = true,
  shrink,
  ...rest
}) => (
  <Grid item xs={6}>
  <TextField
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    error={Boolean(error)} // Convert error to boolean if it's not already
    helperText={
      <span style={{ color: '#d32f2f' }}>
        {helperText}
      </span>
    }
    disabled={disabled}
    variant={variant}
    size={size}
    InputProps={{ style: { height: '40px', ...InputPropsStyle } }}
    fullWidth={fullWidth}
    {...rest}
    InputLabelProps={{
      shrink: shrink,
    }}
  />
  </Grid>
);

export default CustomDisabledTextField;
