interface AssemblyOption {
  value: string;
  label: string;
}

export const pollingOptions: AssemblyOption[] = [
  {
    value: "1",
    label: " BOYS HIGH SCHOOL BRIJ NAGAR KHARIAN",
  },
  {
    value: "2",
    label: "PANCHAYAT GHAR SOHARA",
  },
  {
    value: "3",
    label: "GMS ISMAILPUR",
  },
  {
    value: "4",
    label: " BOYS MIDDLE SCHOOL NOUGRAN",
  },
  {
    value: "5",
    label: "PANCHAYAT GHAR MARALIAN",
  },
  {
    value: "6",
    label: "HORTICULTURE BUILDING MARALIAN",
  },
  {
    value: "7",
    label: "GIRLS MIDDLE SCHOOL KRISHNA NAGAR",
  },
  {
    value: "8",
    label: "MIDDLE SCHOOL KOTLI MIAN FATEH",
  },
  {
    value: "9",
    label: "PRIMARY SCHOOL LANGOTIAN",
  },
  {
    value: "10",
    label: "MIDDLE SCHOOL DHINDEY KHURD ",
  },
  {
    value: "11",
    label: "PANCHAYAT GHAR PRITHIPUR",
  },
  {
    value: "12",
    label: "GPS SIKINDERPUR",
  },
  {
    value: "13",
    label: "BOYS HIGH SCHOOL CHAK JARALAN",
  },
  {
    value: "14",
    label: "BOYS PRIMARY SCHOOL CHAK LALA",
  },
  {
    value: "15",
    label: "GMS Mehmoodpur",
  },
  {
    value: "16",
    label: "GPS SULTANPUR",
  },
  {
    value: "17",
    label: "Govt. Middle School Kotli Charkan",
  },
  {
    value: "18",
    label: "PANCHAYAT GHAR KOTLI CHARKAN",
  },
  {
    value: "19",
    label: "BOYS PRIMARY SCHOOL BAHADUR KHAN",
  },
  {
    value: "20",
    label: "TOWN HALL BISHNAH",
  },
  {
    value: "21",
    label: "GOVT GIRLS HIGHER SECONDARY SCHOOL BISHNAH",
  },
  {
    value: "22",
    label: "BOYS HIGHER SECONDARY SCHOOL BISHNAH",
  },
  {
    value: "23",
    label: "BLOCK OFFICE BISHNAH",
  },
  {
    value: "24",
    label: "BOYS HIGHER SECONDARY SCHOOL BISHNAH ",
  },
  {
    value: "25",
    label: "BOYS PRIMARY SCHOOL CHAK AVTARA",
  },
  {
    value: "26",
    label: " GOVT HIGH SCHOOL BHATYARI",
  },
  {
    value: "27",
    label: "PANCHAYAT GHAR KANAL",
  },
  {
    value: "28",
    label: "GOVT. MS KANAL",
  },
  {
    value: "29",
    label: "BOYS HIGH SCHOOL CHAK MURAR",
  },
  {
    value: "30",
    label: "BOYS MIDDLE SCHOOL CHAK BANA BRAHMNAN",
  },
  {
    value: "31",
    label: "GPS CHUMBIAN BRAHMANA",
  },
  {
    value: "32",
    label: "PANCHAYAT GHAR CHORLI",
  },
  {
    value: "33",
    label: "GPS CHROLI",
  },
  {
    value: "34",
    label: "GOVERNMENT MIDDLE SCHOOL CHUMBIAN JATTAN",
  },
  {
    value: "35",
    label: "BOYS PRIMARY SCHOOL SHRI PARTAP SINGH PURA",
  },
  {
    value: "36",
    label: " BOYS MIDDLE SCHOOL HARSA DABBER",
  },
  {
    value: "37",
    label: "GGOVERNMENT HIGH SCHOOL DEOLI",
  },
  {
    value: "38",
    label: "Girls HiGh school Deoli (Pry.WinG)",
  },
  {
    value: "39",
    label: "PRIMARY SCHOOL LASWARA",
  },
  {
    value: "40",
    label: "BOYS MIDDLE SCHOOL MAKHAN PUR CHARKAN",
  },
  {
    value: "41",
    label: "GIRLS HIGH SCHOOL SALEHAR",
  },
  {
    value: "42",
    label: "PRIMARY SCHOOL CHAK TAHIR",
  },
  {
    value: "43",
    label: "BOYS PRIMARY SCHOOL CHAK JAGATU",
  },
  {
    value: "44",
    label: "BOYS MIDDLE SCHOOL PASGA",
  },
  {
    value: "45",
    label: "BOYS MIDDLE SCHOOL PANDORI BRAHMNAN",
  },
  {
    value: "46",
    label: "DAK BANGLA PANDORI MANHASAN",
  },
  {
    value: "47",
    label: "GIRLS PRIMARY SCHOOL KRAIL MANHASAN",
  },
  {
    value: "48",
    label: "GPS Chak Waziroo",
  },
  {
    value: "49",
    label: "BOYS MIDDLE SCHOOL KOTHEY SAINIAN",
  },
  {
    value: "50",
    label: "BOYS MIDDLE SCHOOL RATTNAL",
  },
  {
    value: "51",
    label: "GIRLS HIGH SECONDARY SCHOOL SARORE",
  },
  {
    value: "52",
    label: "BOYS MIDDLE SCHOOL SARORE",
  },
  {
    value: "53",
    label: "MS CHAK MIAN SUKHA SINGH BANDRALI",
  },
  {
    value: "54",
    label: "GOVT MIDDLE SCHOOL GUDWAL",
  },
  {
    value: "55",
    label: "BOYS MIDDLE SCHOOL KARIYAL BARHAMNAN",
  },
  {
    value: "56",
    label: " BOYS HIGH SCHOOL KHERI",
  },
  {
    value: "57",
    label: " BOYS MIDDLE SCHOOL PANJ PEER CHAKRA JANIB MAGHRIB",
  },
  {
    value: "58",
    label: "BOYS MIDDLE SCHOOL KHOJIPUR",
  },
  {
    value: "59",
    label: "BAL SHIKSHA KENDRA SHAHPUR BRAHMANAN",
  },
  {
    value: "60",
    label: "BOYS HIGHER SECONDARY SCHOOL RAHAL DHAMALIAN",
  },
  {
    value: "61",
    label: "GIRLS MIDDLE SCHOOL RAHAL KALANDRIAN",
  },
  {
    value: "62",
    label: " BOYS HIGH SCHOOL CHAK KEEMA",
  },
  {
    value: "63",
    label: "MIDDLE SCHOOL KOOL KALAN",
  },
  {
    value: "64",
    label: "MIDDLE SCHOOL SOHAGPUR",
  },
  {
    value: "65",
    label: " GOVERNMENT PRIMARY SCHOOL MARHOL",
  },
  {
    value: "66",
    label: "GOVT PRIMARY SCHOOL KARYAL KHURD",
  },
  {
    value: "67",
    label: "GPS FATEGARH",
  },
  {
    value: "68",
    label: "GOVT BOYS HIGH SCHOOL ADLEHAR",
  },
  {
    value: "69",
    label: "Government Middle School Shibu chak",
  },
  {
    value: "70",
    label: "GOVERNMENT PRIMARY SCHOOL BAHADUR PUR",
  },
  {
    value: "71",
    label: "HIGH SCHOOL SAID GARH",
  },
  {
    value: "72",
    label: "GPS MUZAFFERPUR",
  },
  {
    value: "73",
    label: " GOVT MIDDLE SCHOOL MANGHAL",
  },
  {
    value: "74",
    label: "BOYS HIGH SCHOOL MAJUA",
  },
  {
    value: "75",
    label: "PANCHAYAT GHAR CHAK CHAMMA",
  },
  {
    value: "76",
    label: "GOVT PRIMARY SCHOOL RANJIT PUR",
  },
  {
    value: "77",
    label: " GOVT PRIMARY SCHOOL KOTLA",
  },
  {
    value: "78",
    label: " PRIMARY SCHOOL KATHAR",
  },
  {
    value: "79",
    label: "BOYS HIGHER SECONDARY SCHOOL ALLAH",
  },
  {
    value: "80",
    label: "GOVT HIGH SCHOOL ARNIA",
  },
  {
    value: "81",
    label: "GOVERNMENT HIGHER SECONDARY SCHOOL ARNIA",
  },
  {
    value: "82",
    label: "GOVERNMENT PRIMARY SCHOOL ARNIA",
  },
  {
    value: "83",
    label: "GOVT HIGH SCHOOL ARNIA",
  },
  {
    value: "84",
    label: "Office of the zilladar, Irri.Arnia",
  },
  {
    value: "85",
    label: " GOVT HIGH SCHOOL ARNIA",
  },
  {
    value: "86",
    label: "GOVT GIRLS MIDDLE SCHOOL ARNIA",
  },
  {
    value: "87",
    label: "GOVERNMENT HIGHER SECONDARY SCHOOL TREWA",
  },
  {
    value: "88",
    label: " GOVT PRIMARY SCHOOL RANGPUR TREWA",
  },
  {
    value: "89",
    label: "PANCHAYAT GHAR RANGPUR TREWA",
  },
  {
    value: "90",
    label: "GOVT PRIMARY SCHOOL JABOWAL KHURD",
  },
  {
    value: "91",
    label: " GOVT PRIMARY SCHOOL CHANGIA",
  },
  {
    value: "92",
    label: " PRIMARY SCHOOL KADWAL",
  },
  {
    value: "93",
    label: "PRIMARY SCHOOL PINDI CHARKAN KALAN",
  },
  {
    value: "94",
    label: "GPS PINDI CAMP ",
  },
  {
    value: "95",
    label: " GOVERNMENT MIDDLE SCHOOL SAMKA",
  },
  {
    value: "96",
    label: " GOVERNMENT GIRLS PRIMARY SCHOOL SAMKA",
  },
  {
    value: "97",
    label: "GOVERNMENT PRIMARYSCHOOL FATEHPUR BRAHMANA",
  },
  {
    value: "98",
    label: "GOVERNMENT PRIMARY SCHOOL BADILA",
  },
  {
    value: "99",
    label: "AGOVERNMENT GIRLS HIGH SCHOOL BADYAL BRAHMANA",
  },
  {
    value: "100",
    label: " DPANCHAYAT GHAR BADYAL BRAHMANA LOWER",
  },
  {
    value: "101",
    label: "DPANCHAYAT GHAR BADYAL BRAHMANA UPPER",
  },
  {
    value: "102",
    label: "GOVERNMENT BOYS MIDDLE SCHOOL CHAK AGRA",
  },
  {
    value: "103",
    label: "GOVERNMENT MIDDLE SCHOOL BERA",
  },
  {
    value: "104",
    label: "GOVERNMENT HIGH SCHOOL CHANDU CHAK",
  },
  {
    value: "105",
    label: " DAK BANGLA BASPUR",
  },
  {
    value: "106",
    label: "GOVERNMENT MIDDLE SCHOOL KOTLI MIRDIAN",
  },
  {
    value: "107",
    label: "GOVERNMENT PRIMARY SCHOOL KADYAL",
  },
  {
    value: "108",
    label: "PANCHAYAT GHAR JASSORE",
  },
  {
    value: "109",
    label: " AGOVERNMENT GIRLS MIDDLE SCHOOL KOTLI ARJUN SINGH",
  },
  {
    value: "110",
    label: "BPANCHAYAT GHAR KOTLI ARJUN SINGH",
  },
  {
    value: "111",
    label: " GOVERNMENT MIDDLE SCHOOL KHANA CHAK ",
  },
  {
    value: "112",
    label: "GOVERNMENT GIRLS HIGH SCHOOL RANGPUR MULANIAN",
  },
  {
    value: "113",
    label: "GOVERNMENT GIRLS HIGH SCHOOL RANGPUR MULANIAN ",
  },
  {
    value: "114",
    label: "GOVERNMENT PRIMARY SCHOOL KOROTANA BAST",
  },
  {
    value: "115",
    label: "  PANCHAYAT GHAR SUCHET GARH",
  },
  {
    value: "116",
    label: "GOVERNMENT GIRLS HIGH SCHOOL KALOYEN",
  },
  {
    value: "117",
    label: "GOVERNMENT BOYS PRIMARY SCHOOL FLORA",
  },
  {
    value: "118",
    label: "GOVERNMENT MIDDLE SCHOOL MOTEY",
  },
  {
    value: "119",
    label: " PANCHAYAT GHAR SATRAYAN KHURD",
  },
  {
    value: "120",
    label: "GOVERNMENT GIRLS HIGH SCHOOL SATRAYAN KHURD",
  },
  {
    value: "121",
    label: " MONTESSORI NARGIS DUTT HIGH SCHOOL KOTLI GALABANA",
  },
  {
    value: "122",
    label: " BOYS HIGH SCHOOL SIMBAL CAMP (WEST SIDE)",
  },
  {
    value: "123",
    label: "GIRLS HIGH SCHOOL NIHAL PUR SIMBAL CAMP",
  },
  {
    value: "124",
    label: "PHC SIMBAL CAMP",
  },
  {
    value: "125",
    label: "BOYS MIDDLE SCHOOL GAZIPUR KULIAN",
  },
  {
    value: "126",
    label: " GOVERNMENT MIDDLE SCHOOL SATRAYAN",
  },
  {
    value: "127",
    label: "GOVERNMENT HIGH SCHOOL BADYAL QAZIAN",
  },
  {
    value: "128",
    label: "GOVERNMENT PRIMARY SCHOOL MOKHEY",
  },
  {
    value: "129",
    label: " GOVERNMENT HIGH SCHOOL HARI PUR",
  },
  {
    value: "130",
    label: "GOVERNMENT GIRLS HIGH SCHOOL NAI BASTI ABDA",
  },
  {
    value: "131",
    label: "GOVERNMENT PRIMARY SCHOOL KAPOOR PUR",
  },
  {
    value: "132",
    label: "PANCHAYAT GHAR GHARANA",
  },
  {
    value: "133",
    label: "GOVERNMENT GIRLS PRIMARY SCHOOL GHARANI",
  },
  {
    value: "134",
    label: "GOVERNMENT PRIMARY SCHOOL DANGREY",
  },
  {
    value: "135",
    label: "PANCHAYAT GHAR CHAK BAJA",
  },
  {
    value: "136",
    label: "BOYS MIDDLE SCHOOL CHAK MUSA",
  },
  {
    value: "137",
    label: " BOYS MIDDLE SCHOOL CHOHALA",
  },
  {
    value: "138",
    label: " GIRLS MIDDLE SCHOOL CHOHALA",
  },
  {
    value: "139",
    label: "BOYS MIDDLE SCHOOL TANDA",
  },
  {
    value: "140",
    label: " GOVERNMENT GIRLS MIDDLE SCHOOL RATIYAAN",
  },
  {
    value: "141",
    label: "BOYS HIGH SCHOOL KIRPIND",
  },
  {
    value: "142",
    label: " MIDDLE SCHOOL BAGAZINA",
  },
  {
    value: "143",
    label: " GIRLS MIDDLE SCHOOL CHAK ISLAM",
  },
  {
    value: "144",
    label: "GIRLS PRIMARY SCHOOL CHAK MOHAMMAD YAR",
  },
  {
    value: "145",
    label: " PRIMARY SCHOOL GONDLA",
  },
  {
    value: "146",
    label: "PANCHAYAT GHAR MAGOWALI",
  },
  {
    value: "147",
    label: "GOVERNMENT MIDDLE SCHOOL MANA",
  },
  {
    value: "148",
    label: " PANCHAYAT GHAR CHAKROHI",
  },
  {
    value: "149",
    label: " GOVERNMENT GIRLS HIGH SCHOOL CHAKROHI",
  },
  {
    value: "150",
    label: " GOVERNMENT HIGHER SECONDARY SCHOOL",
  },
  {
    value: "151",
    label: " GOVERNMENT HIGHER SECONDARY SCHOOL CHAKROHI",
  },
  {
    value: "152",
    label: " ANCHAYAT GHAR JAJOWAL",
  },
  {
    value: "153",
    label: " GOVERNMENT PRIMARY SCHOOL NEKOWAL",
  },
  {
    value: "154",
    label: " OVERNMENT MIDDLE SCHOOL SAI KHURD SARDARI",
  },
  {
    value: "155",
    label: "GOVERNMENT MIDDLE SCHOOL DEEWAN GARH",
  },
  {
    value: "156",
    label: "GOVERNMENT PRIMARY SCHOOL KHEROWAL",
  },
  {
    value: "157",
    label: " GOVERNMENT HIGH SCHOOL BIASPUR",
  },
  {
    value: "158",
    label: "GOVERNMENT GIRLS HIGH SCHOOL BALAH",
  },
  {
    value: "159",
    label: "GOVERNMENT GIRLS HIGH SCHOOL DABLEHAR",
  },
  {
    value: "160",
    label: "GOVERNMENT HIGHER SECONDARY SCHOOL DABLEHAR",
  },
  {
    value: "161",
    label: " GIRLS MIDDLE SCHOOL CHAK ISLAM",
  },
  {
    value: "162",
    label: "GPS QUTABNIZAM ",
  },
  {
    value: "163",
    label: "  BOYS MIDDLE SCHOOL KHOUR DEONIAN",
  },
  {
    value: "164",
    label: "GIRLS PRIMARY SCHOOL MUMKA",
  },
  {
    value: "165",
    label: "PANCHYAT GHAR BAHU",
  },
  {
    value: "166",
    label: "PRIMARY SCHOOL RAIPUR GUJJRAIN",
  },
  {
    value: "167",
    label: "BOYS HIGH SCHOOL JINDER MELU",
  },
  {
    value: "168",
    label: "PRIMARY SCHOOL CHAK BALA",
  },
  {
    value: "169",
    label: " PANCHAYAT GHAR PARLAH",
  },
  {
    value: "170",
    label: "LANGOVERNMENT HIGH SCHOOL PINDI SAROCHAN KALAN",
  },
  {
    value: "171",
    label: "GOVERNMENT GIRLS PRIMARY SCHOOL SAI KALAN TANGAL",
  },
  {
    value: "172",
    label: " GOVERNMENT HIGHER SECONDARY SCHOOL SAI KALAN",
  },
  {
    value: "173",
    label: "GOVERNMENT GIRLS PRIMARY SCHOOL SAI KALAN TANGALI",
  },
  {
    value: "174",
    label: "GOVERNMENT MIDDLE SCHOOL TALHAR",
  },
  {
    value: "175",
    label: "GOVERNMENT PRIMARY SCHOOL JANGWAL",
  },
  {
    value: "176",
    label: "GIRLS HIGH SCHOOL KALYANA",
  },
  {
    value: "177",
    label: "BOYS MIDDLE SCHOOL RATHANA CAM",
  },
  {
    value: "178",
    label: " PRIMARY SCHOOL RATHANA CAMP",
  },
  {
    value: "179",
    label: "Girls MIDDLE SCHOOL PHINDER",
  },
  {
    value: "180",
    label: "PANCHYAT GHAR PHINDER",
  },
  {
    value: "181",
    label: "MIDDLE SCHOOL KOTLI BHAGWAN SINGH",
  },
  {
    value: "182",
    label: " BOYS PRIMARY SCHOOL GANDLI",
  },
  {
    value: "183",
    label: "MIDDLE SCHOOL KHANDWAL",
  },
  {
    value: "184",
    label: "GOVT MIDDLE SCHOOL NANDWAL",
  },
  {
    value: "185",
    label: " MIDDLE SCHOOL HAKKAL",
  },
  {
    value: "186",
    label: "  GOVERNMENT HIGH SCHOOL RAI PUR SATWARI",
  },
  {
    value: "187",
    label: " GIRLS HIGHER SECONDARY SCHOOL SATWARI",
  },
  {
    value: "188",
    label: "LABORATORY BUILDING CHATHA",
  },
  {
    value: "189",
    label: "GOVERNMENT HIGH SCHOOL CHATHA",
  },
  {
    value: "190",
    label: " BOYS MIDDLE SCHOOL GAZIPUR KULIAN",
  },
  {
    value: "191",
    label: " MIDDLE SCHOOL KHEPAR NARI",
  },
  {
    value: "192",
    label: "BOYS HIGH SCHOOL KOTLI SHAH DOULA",
  },
  {
    value: "193",
    label: "  GIRLS HIGHER SEC SCHOOLRANBIR SINGH PURA",
  },
  {
    value: "194",
    label: " PRIMARY SCHOOL RANGPUR SADREY",
  },
  {
    value: "195",
    label: "MS PURANA PIND",
  },
  {
    value: "196",
    label: "HIGHER SECONDARY SCHOOL RANBIR SINGH PURA",
  },
  {
    value: "197",
    label: "BOYS CITY MIDDLE SCHOOL RANBIR SINGH PURA",
  },
  {
    value: "198",
    label: " GIRLS HIGHER SEC SCHOOL RANBIR SINGH PURA",
  },
  {
    value: "199",
    label:
      "  SHER E KASHMIR AGRICULTURE UNIVERSITY RANBIR SINGH PURA (EAST SIDE)",
  },
  {
    value: "200",
    label: " MIDDLE SCHOOL MILL AREA RS PORA",
  },
  {
    value: "201",
    label: " HIGHER SECONDARY SCHOOL RANBIR SINGH PURA",
  },
  {
    value: "202",
    label: " BLOCK OFFICE BUILDING RS PURA",
  },
  {
    value: "203",
    label: "RIMARY SCHOOL MELOWAL",
  },
  {
    value: "204",
    label: " GIRLS PRIMARY SCHOOL DHANIDAY KALAN",
  },
  {
    value: "205",
    label: "PRIMARY SCHOOL BASTI SIMBAL",
  },
  {
    value: "206",
    label: " GIRLS PRIMARY SCHOOL DHANIDAY KALAN",
  },
  {
    value: "207",
    label: " HIGH SCHOOL BASTI GOVIND PURA SIMBAL BASTI",
  },
  {
    value: "208",
    label: " BUILDING IRRIGATION PANSAL NIWAS RATIAN HEAD PWD SHED",
  },
  {
    value: "209",
    label: "  PANCHAYAT GHAR DARSOPUR",
  },
  {
    value: "210",
    label: "PRIMARY SCHOOL GHAZIA",
  },
  {
    value: "211",
    label: "GIRLS PRIMARY SCHOOL MUMKA",
  },
  {
    value: "212",
    label: "SOIL CONSERVATION BUILDING MALIKPUR",
  },
  {
    value: "213",
    label: "PRIMARY SCHOOL NANDPUR",
  },
  {
    value: "214",
    label: "GIRLS HIGHER SECONDARY SCHOOL MIRAN SAHIB",
  },
  {
    value: "215",
    label: " BOYS HIGH SCHOOL BANSULTAN",
  },
  {
    value: "216",
    label: " GIRLS HIGH SCHOOL BHORE",
  },
  {
    value: "217",
    label: "GIRLS MIDDLE SCHOOL WARD NO 1 BHORE",
  },
  {
    value: "218",
    label: " GIRLS HIGH SCHOOL BHORE",
  },
  {
    value: "219",
    label: " BOYS HIGHER SECONDARY SCHOOL BHORE",
  },
  {
    value: "220",
    label: " BOYS MIDDLE SCHOOL UPPER GADI GARH",
  },
  {
    value: "221",
    label: " GOVERNMENT GIRLS PRIMARY SCHOOL UPPER GADI GARH",
  },
  {
    value: "222",
    label: " GOVERNMENT MODEL BOYS MIDDLE SCHOOL SATWARI",
  },
  {
    value: "223",
    label: " GOVERNMENT BOYS HIGH SCHOOL SATWARI CANTT",
  },
  {
    value: "224",
    label: "GIRLS HIGHER SECONDARY SCHOOL SATWARI",
  },
  {
    value: "225",
    label: " GOVERNMENT HIGHER SECONDARY SCHOOL GANDHI NAGAR",
  },
  {
    value: "226",
    label: " PHE CITY DIV II SHASTRI NAGAR JAMMU",
  },
  {
    value: "227",
    label: " BOYS HIGH SCHOOL DIGIANA",
  },
  {
    value: "228",
    label:
      "SHIKSHA NIKETAN HIGHER SECONDARY SCHOOL JEEVAN NAGAR OPP INDUSTRIAL ESTATE DIGIANA",
  },
  {
    value: "229",
    label: " GOVT MIDDLE SCHOOL BABLIANA",
  },
  {
    value: "230",
    label: "BR PLAY WAY SCHOOL GANGYAL",
  },
  {
    value: "231",
    label: "SENT PAL CONVENT HIGH SCHOOL GANGYAL",
  },
  {
    value: "232",
    label: "GIRLS PRIMARY SCHOOL GANGIYAL WATER GROUND",
  },
  {
    value: "233",
    label: "GIRLS PRIMARY SCHOOL LANGAR DELI",
  },
  {
    value: "234",
    label: "  GIRLS PRIMARY SHANKAR COLONY",
  },
  {
    value: "235",
    label: " SAINIK COPERATIVE HOUSE BUILDING SOCIETY SAINIK COLONY",
  },
  {
    value: "236",
    label: " TUBE WELL BUILDING SUNJWAN LANGAR",
  },
  {
    value: "237",
    label: " GOVT GIRLS PRIMARY SCHOOL CHOWADHI",
  },
  {
    value: "238",
    label: " GOVT GIRLS PRIMARY SCHOOL CHOWADHI",
  },
  {
    value: "239",
    label: " SAINIK PUBLIC SCHOOL SAINIK COLONY CHOWADHI",
  },
  {
    value: "240",
    label: "GOVT MIDDLE SCHOOL RATTNU CHAK",
  },
  {
    value: "241",
    label: "  GOVT PRIMARY SCHOOL JALLO CHAK",
  },
  {
    value: "242",
    label: "ANIMAL HUSBANDARY BULIDING LOWER JALLO CHAK",
  },
  {
    value: "243",
    label: "GOVT GIRLS MIDDLE SCHOOL KALUCHAK",
  },
  {
    value: "244",
    label: "GOVT HIGH SCHOOL GREATER KAILASH (LOWER CHOWADHI)",
  },
  {
    value: "245",
    label: " GOVERNMENT MIDDLE SCHOOL LANGER KUNJWANI",
  },
  {
    value: "246",
    label: " PANCHAYAT GHAR BUILDING LANGAR",
  },
  {
    value: "247",
    label: " GURU GOBIND SINGH HIGHER SECONDARY SCHOOL KUNJWANI",
  },
  {
    value: "248",
    label: "  GOVERNMENT HIGH SCHOOL DEELI",
  },
  {
    value: "249",
    label: "  TUBE WELL BUILDING KUNJWANI TALAB",
  },
  {
    value: "250",
    label: " GURU GOBIND SINGH HIGHER SECONDARY SCHOOL KUNJWANI",
  },
  {
    value: "251",
    label: " BOYS HIGH SCHOOL GIDDAR GALIYAN",
  },
  {
    value: "252",
    label: " BOYS PRIMARY SCHOOL ",
  },
  {
    value: "253",
    label: "BOYS HIGHER SECONDARY SCHOOL ",
  },
  {
    value: "254",
    label: " GOVERNMENT GIRLS MIDDLE SCHOOL ",
  },
  {
    value: "255",
    label: " BOYS MIDDLE SCHOOL BOURIA",
  },
  {
    value: "256",
    label: "GOVT HIGH SCHOOL QASIM ",
  },
  {
    value: "257",
    label: "GOVT MIDDLE SCHOOL NARWA ",
  },
  {
    value: "258",
    label: "GIRLS MIDDLE SCHOOL KARYA ",
  },
  {
    value: "259",
    label: " GOVT MIDDLE SCHOOL NARWA ",
  },
  {
    value: "260",
    label: "  BOYS PRIMARY SCHOOL NARWA",
  },
  {
    value: "261",
    label: "GIRLS MIDDLE SCHOOL NARWA ",
  },
  {
    value: "262",
    label: "ANIMAL HUSBANDARY FARM BE ",
  },
  {
    value: "263",
    label: " GIRLS MIDDLE SCHOOL KALIAN",
  },
  {
    value: "264",
    label: " BOYS HIGH SCHOOL OLD SATWA",
  },
  {
    value: "265",
    label: " PEOPLES ACADEMY GOBIND PUR",
  },
  {
    value: "266",
    label: "GOVERNMENT HIGH SCHOOL ",
  },
  {
    value: "267",
    label: " COMMUNITY HALL MALIK MARK",
  },
  {
    value: "268",
    label: " GOVT HIGH SCHOOL CHANI ",
  },
  {
    value: "269",
    label: " GOVT GIRLS HIGH SCHOOL",
  },
  {
    value: "270",
    label: "LUTHRA ACADEMY LAST MORH ",
  },
  {
    value: "271",
    label: "GOVERNMENT HIGHER SECOND ",
  },
  {
    value: "272",
    label: " KENDARIA VIDIYALE NO I  ",
  },
  {
    value: "273",
    label: "GOVERNMENT GIRLS HIGH SCHOOL ",
  },
  {
    value: "274",
    label: "LUTHRA ACADEMY NANAK NAGAR ",
  },
  {
    value: "275",
    label: "BAL BHARTI SCHOOL SECTOR 1 ",
  },
  {
    value: "276",
    label: "GURU HARKRISHAN PUBLC SCHOOL  ",
  },
  {
    value: "277",
    label: " PREET PUBLIC HIGH SCHOOL ",
  },
  {
    value: "278",
    label: " MODERN SCHOOL NANAK NAGAR ",
  },
  {
    value: "279",
    label: " BEE HOUSE(PRY) TRIKUTA NAGAR ",
  },
  {
    value: "280",
    label: " NEW ERA MODERN SCHOOL NANAK",
  },
  {
    value: "281",
    label: " RN ACADEMY NANAK NAGAR",
  },
  {
    value: "282",
    label: " BLOOMING DALE PUBLIC HIGH SCHOOL ",
  },
  {
    value: "283",
    label: " MODERN ACADEMY, SECTOR 6",
  },
  {
    value: "284",
    label: " RN TAGORE HIGHER SECONDARY ",
  },
  {
    value: "285",
    label: " URBAN HEALTH CENTER SECTOR",
  },
  {
    value: "286",
    label: " CENTRAL COOPERATIVE SCHOOL ",
  },
  {
    value: "287",
    label: "  VASUDA KATUMBKAM SCHOOL",
  },
  {
    value: "288",
    label: "  TEHSIL OFFICE BAHU NEAR MARBLE ",
  },
  {
    value: "289",
    label: " POPULAR ACADEMY SCHOOL NANAK ",
  },
  {
    value: "290",
    label: " VIKAS MODEL PUBLIC HIGH SCHOOL",
  },
  {
    value: "291",
    label: " MIDDLE SCHOOL MARAKHAR",
  },
  {
    value: "292",
    label: "  BOYS HIGHER SECONDARY SCHOOL",
  },
  {
    value: "293",
    label: "  GOVERNMENT GIRLS MIDDLE SCHOOL ",
  },
  {
    value: "294",
    label: "  SUSHMA SHINING STAR MIDDLE",
  },
  {
    value: "295",
    label: " TENDER TOES NURSERY SCHOOL",
  },
  {
    value: "296",
    label: " TAGORE HIGHER SECONDARY SCHOOL  ",
  },
  {
    value: "297",
    label: "  PHE BUILDING NEAR PARK DIGIAN",
  },
  {
    value: "298",
    label: " TAGORE MEMORIAL HIGHER SECONDARY SCHOOL ",
  },
  {
    value: "299",
    label: " SHISHU MANDIR PUBLIC HIGH SCHOOL",
  },
  {
    value: "300",
    label: "GIRLS MIDDLE SCHOOL DIGIANA ",
  },
  {
    value: "301",
    label: "BOYS MIDDLE SCHOOL DIGIANA ",
  },
  {
    value: "302",
    label: "BOYS HIGH SCHOOL BATHINDI ",
  },
  {
    value: "303",
    label: "  GOVT HIGHER SECONDARY SCHOOL ",
  },
  {
    value: "304",
    label: " HIGH SCHOOL CHATTA",
  },
  {
    value: "305",
    label: " GOVT MIDDLE SCHOOL SIMBAL LEHAR",
  },
  {
    value: "306",
    label: "GOVERNMENT MIDDLE SCHOOL UPPER JAGTI ",
  },
  {
    value: "307",
    label: " GOVT MIDDLE SCHOOL UPPER JAGTI",
  },
  {
    value: "308",
    label: " PANCHAYAT GHAR JAGTI ",
  },
  {
    value: "309",
    label: "  GOVERNMENT HIGH SCHOOL DHAMMI",
  },
  {
    value: "310",
    label: "GIRLS MIDDLE SCHOOL REHARI NAZDIK POLICE POST SARWAL ",
  },
  {
    value: "311",
    label: "  BOYS HIGHER SECONDARY SCHOOL REHARI COLONY",
  },
  {
    value: "312",
    label: " STATE MOTOR GARAGE NEW PLOT ",
  },
  {
    value: "313",
    label: "WATER WORKS OFFICE BUILDING MASTER PLAN JAMMU ",
  },
  {
    value: "314",
    label: " ASHOKA INSTITUTE OF ELEMENTARY TEACHER TRAINING BUILDING",
  },
  {
    value: "315",
    label: " GOVERNMENT MIDDLE SCHOOL SIDHRA ",
  },
  {
    value: "316",
    label: "  GOVT PRIMARY SCHOOL ASRARABAD",
  },
  {
    value: "317",
    label: " MIDDLE SCHOOL MAJHEEN",
  },
  {
    value: "318",
    label: " GOVERNMENT MIDDLE SCHOOL RANGOORA",
  },
  {
    value: "319",
    label: " GOVERNMENT PRIMARY SCHOOL LOWER RANGOOR",
  },
  {
    value: "320",
    label: " GOVERNMENT PRIMARY SCHOOL KUPPER",
  },
  {
    value: "321",
    label: "GOVERNMENT HIGH SCHOOL CHAK LARGAN ",
  },
  {
    value: "322",
    label: "  PWD BUILDING MAIN MARKET PARGALTA BAJALTA",
  },
  {
    value: "323",
    label: "GOVERNMENT LOWER HIGH SCHOOL BAIN BAJALTA ",
  },
  {
    value: "324",
    label: "  GOVERNMENT MIDDLE SCHOOL BHERGRAN",
  },
  {
    value: "325",
    label: " BOYS HIGH SCHOOL AITHEM",
  },
  {
    value: "326",
    label: "  GOVT MIDDLE SCHOOL SAIL JAGIR",
  },
  {
    value: "327",
    label: " GOVT MIDDLE SCHOOL CHARGWALI ",
  },
  {
    value: "328",
    label: " ZILA SAINIK BOARD OFFICE",
  },
  {
    value: "329",
    label: "JAMBULOACHAN COMMUNITY HALL JAMMU ",
  },
  {
    value: "330",
    label: " GARAGE OF FIRE SERVICE GROUND FLOOR ",
  },
  {
    value: "331",
    label: " LOTHRA ACADEMY MOHALLA NARAINA EAST SIDE",
  },
  {
    value: "332",
    label: " SD SABHA HIGHER SECONDARY SCHOOL REHARI WEST SIDE ",
  },
  {
    value: "333",
    label: " SD SABHA HIGHER SECONDARY SCHOOL REHARI EAST SIDE",
  },
  {
    value: "334",
    label: "  SD SABHA HIGHER SECONDARY SCHOOL REHAR",
  },
  {
    value: "335",
    label: " SOCIAL WELFARE CENTER REHARI",
  },
  {
    value: "336",
    label: " FOREST BUILDING DOGRA HALL WESTERN SIDE",
  },
  {
    value: "337",
    label: "  FOREST BUILDING DOGRA HALL",
  },
  {
    value: "338",
    label: " FOREST BUILDING DOGRA HALL LEFT SIDE ",
  },
  {
    value: "339",
    label: " WATER WORKS BUILDING DHONTLI ",
  },
  {
    value: "340",
    label: "  GIRLS MIDDLE SCHOOL KACHI CHAWNI",
  },
  {
    value: "341",
    label: "ANGIRLS HIGHER SECONDARY SCHOOL MUBARK MANDI ",
  },
  {
    value: "342",
    label: "D B N SCHOOL MUBARAK MANDI ",
  },
  {
    value: "343",
    label: " GOVRNMENT GIRLS HIGHER SECONDARY SCHOOL  MUBARAK MANDI",
  },
  {
    value: "344",
    label: " GOVERNMENT HIGHER SECONDARY SCHOOL JULLAKA MOHALLA",
  },
  {
    value: "345",
    label: " DGOVERNMENT HIGHER SECONDARY SCHOOL JULLAKA MOHALLA",
  },
  {
    value: "346",
    label: "  SANATAN DHARAM KANNIA PATHSHALA MOHALLA JULLAKA",
  },
  {
    value: "347",
    label: " SANATAN DHARAM SCHOOL MOHALLA JULLAKA MOHALLA ",
  },
  {
    value: "348",
    label: "LAKHAN ACADEMY MOHALLA JULLAKA ",
  },
  {
    value: "349",
    label: "  BRIGHT BLOSSOM PUBLIC SCHOOL PURANI MANDI JAMMU",
  },
  {
    value: "350",
    label: " ST VIVEKANAND INSITITUTE OF EDU MOHALLA BHABARIYAN ",
  },
  {
    value: "351",
    label: "ARYA KANNIYA VIDIYALE PATEL CHOWK ",
  },
  {
    value: "352",
    label: "GRROMAT KANNIYA PATHSHALA CHOGAN FATU ",
  },
  {
    value: "353",
    label: " GIRLS HIGH SCHOOL KACHI CHAWNI EAST SIDE",
  },
  {
    value: "354",
    label: " JAGRITI BALBARI HIGH SCHOOL IN THE GROUND FLOOR ",
  },
  {
    value: "355",
    label: "  WOMEN COLLEGE PRADE GROUND JAMMU",
  },
  {
    value: "356",
    label: "  GEETA BHAWAN JAMMU MOHALLA PAHARIAN",
  },
  {
    value: "357",
    label: "GIRLS HIGH SCHOOL BUILDING MOHALLA PAHARIAN ",
  },
  {
    value: "358",
    label: " CENTRAL BASIC HIGHER SECONDARY SCHOOL PURANI MANDI",
  },
  {
    value: "359",
    label: " TEHSIL OFFICE JAMMU",
  },
  {
    value: "360",
    label: "WATER WORKS OFFICE WAZARAT ROAD JAMMU ",
  },
  {
    value: "361",
    label: "CUSTODIAN OFFICE JAMMU ",
  },
  {
    value: "362",
    label: " OFFICE OF DEPUTY DIRECTOR TOURISM DAK BANGLA JAMMU ",
  },
  {
    value: "363",
    label: "ALEXENDER MEMORIAL SCHOOL BUILDING PARTAP GARH ",
  },
  {
    value: "364",
    label: " MUNICIPAL CORPORATION OFFICE BUILDING TOWN HALL JAMMU",
  },
  {
    value: "365",
    label: "  GOVERNMENT AYURVEDIC HOSPITAL BUILDING JAMMU",
  },
  {
    value: "366",
    label: " JAMMU PUBLIC SCHOOL TALAB KHTIKAN",
  },
  {
    value: "367",
    label: "HIGH SCHOOL GHAS MANDI JAMMU TALAB KHATIKAN ",
  },
  {
    value: "368",
    label: " MATERIAL TESTING LABORATORY NEAR POWER HOUSE",
  },
  {
    value: "369",
    label: "  HARI SINGH HIGHERSECONDARY SCHOOL GANDU CHAWANI",
  },
  {
    value: "370",
    label: " SOCIAL WELFARE CENTER CHRISTIAN COLONY JOGI GATE ",
  },
  {
    value: "371",
    label: "RAJPUT SCHOOL JOGI GATE ",
  },
  {
    value: "372",
    label: " GOVERMENT HIGHER SEC SCHOOL BAZAR QUASABA JAMMU ",
  },
  {
    value: "373",
    label: " KHALSA HIGHER SECONDARY SCHOOL CHAND NAGAR JAMMU",
  },
  {
    value: "374",
    label: " SCIENCE COLLEGE BUILDING JAMMU ",
  },
  {
    value: "375",
    label: " OFFICE GENERAL MANAGER OPERATIONAL SRTC TAWI PAR",
  },
  {
    value: "376",
    label: "L GOVERNMENT HIGHER SECONDARY SCHOOL KHANA CHHARGAL ",
  },
  {
    value: "377",
    label: "GMS JHAKKAR",
  },
  {
    value: "378",
    label: "HS GORDA",
  },
  {
    value: "379",
    label: "MS DUNG",
  },
  {
    value: "380",
    label: "MS DHANU",
  },
  {
    value: "381",
    label: "GMS BHAGANI",
  },
  {
    value: "382",
    label: "GPS KHARAD",
  },
  {
    value: "383",
    label: "BOYS HSS RABTA",
  },
  {
    value: "384",
    label: "GPS HANDWAL",
  },
  {
    value: "385",
    label: " GPS ORAK",
  },
  {
    value: "386",
    label: "GMS CHIRANGAL",
  },
  {
    value: "387",
    label: "Boys HS Keri",
  },
  {
    value: "388",
    label: "GPS PADMI",
  },
  {
    value: "389",
    label: "GOVT MIDDLE SCHOOL KALYAR",
  },
  {
    value: "390",
    label: "PANCHAYAT GHAR MATHWAR",
  },
  {
    value: "391",
    label: "HS MATHWAR",
  },
  {
    value: "392",
    label: "GMS MAWA",
  },
  {
    value: "393",
    label: " MS SAROTE",
  },
  {
    value: "394",
    label: "GPS RATTI",
  },
  {
    value: "395",
    label: "HS RANJAN (THATHI)",
  },
  {
    value: "396",
    label: "GIRLS HS AGORE",
  },
  {
    value: "397",
    label: "GPS LOONI MOHALLA",
  },
  {
    value: "398",
    label: "GPS RANJAN LOWER",
  },
  {
    value: "399",
    label: "TUBE WELL BUILDING RANJAN",
  },
  {
    value: "400",
    label: "GMS GRAND SUKKA",
  },
  {
    value: "401",
    label: "GPS BERI CHAPPRI",
  },
  {
    value: "402",
    label: "Govt. P/S Gurah Pattan",
  },
  {
    value: "403",
    label: "BHS Dumi",
  },
  {
    value: "404",
    label: "M/S Malpur",
  },
  {
    value: "405",
    label: "Boys P/S Ban",
  },
  {
    value: "406",
    label: "BPS Sobka",
  },
  {
    value: "407",
    label: "BHS Gharota",
  },
  {
    value: "408",
    label: "GOVT HSS GHROTA",
  },
  {
    value: "409",
    label: "Banchayat Ghar Behra (Lower Ghrota)",
  },
  {
    value: "410",
    label: "M/S Marjali",
  },
  {
    value: "411",
    label: "Vir Ram Chander Memorial H/S Batera",
  },
  {
    value: "412",
    label: "MS Dharam Khoo",
  },
  {
    value: "413",
    label: "H/S Kangerail",
  },
  {
    value: "414",
    label: "GOVT AEE OFFICE PHE BALWAN UPPER BARAN",
  },
  {
    value: "415",
    label: " BPS Barn Upper",
  },
  {
    value: "416",
    label: "P/S Baran Patta",
  },
  {
    value: "417",
    label: "P/S Chack Bhalwal",
  },
  {
    value: "418",
    label: "H/S Seri Panditan",
  },
  {
    value: "419",
    label: "P/S Dhar Dharochan",
  },
  {
    value: "420",
    label: "G/P/S Kaink",
  },
  {
    value: "421",
    label: " G/P/S Nandwal",
  },
  {
    value: "422",
    label: "GPS NARGARA",
  },
  {
    value: "423",
    label: "PS CHHUA",
  },
  {
    value: "424",
    label: "PANCHAYAT GHAR BAMYA",
  },
  {
    value: "425",
    label: "GHSS GUNDLA",
  },
  {
    value: "426",
    label: "GIRLS HSS MARH",
  },
  {
    value: "427",
    label: "GPS DUNG",
  },
  {
    value: "428",
    label: "GPS DRABHI",
  },
  {
    value: "429",
    label: "MS DHAMUNI",
  },
  {
    value: "430",
    label: "MS CHHIBA",
  },
  {
    value: "431",
    label: "PANCHAYAT GHAR SERI KHURD",
  },
  {
    value: "432",
    label: "HIGH SCHOOL PANJGRAIN",
  },
  {
    value: "433",
    label: "GPS SARMOHI",
  },
  {
    value: "434",
    label: "GMS LOWER KORE JAGIR",
  },
  {
    value: "435",
    label: "PS NADORE",
  },
  {
    value: "436",
    label: "BOYS HS NAGROTA",
  },
  {
    value: "437",
    label: "Govt. PS Dhok waziran",
  },
  {
    value: "438",
    label: "GMS SITNI BYE PASS",
  },
  {
    value: "439",
    label: "PS KHANPUR",
  },
  {
    value: "440",
    label: "PS CHAK RAKWALA",
  },
  {
    value: "441",
    label: "GPS NARYAN KHOO",
  },
  {
    value: "442",
    label: "High School Katal Batal",
  },
  {
    value: "443",
    label: "High School Katal Batal",
  },
  {
    value: "444",
    label: "Boys HS Tahara",
  },
  {
    value: "445",
    label: "PS SELLI",
  },
  {
    value: "446",
    label: "PRIMARY SCHOOL JANAKHA",
  },
  {
    value: "447",
    label: "HIGH SCHOOL SUKETAR DOMAIL",
  },
  {
    value: "448",
    label: "HSS JHAJJAR KOTLI",
  },
  {
    value: "449",
    label: "PANCHAYAT GHAR JHAJJAR KOTLI",
  },
  {
    value: "450",
    label: "MIDDLE SCHOOL CHHURTA",
  },
  {
    value: "451",
    label: "GMS SANDROTE",
  },
  {
    value: "452",
    label: "BOYS HSS DANSAL",
  },
  {
    value: "453",
    label: "GMS CHADALI",
  },
  {
    value: "454",
    label: "HIGH SCHOOL BADSOO",
  },
  {
    value: "455",
    label: "GPS URLANI",
  },
  {
    value: "456",
    label: "BOYS HSS KAH POTA",
  },
  {
    value: "457",
    label: " Govt Primary School Megh Mohalla(Kanyala)",
  },
  {
    value: "458",
    label: "Boys MS Kanyala",
  },
  {
    value: "459",
    label: "BOYS HSS JANDRAH",
  },
  {
    value: "460",
    label: "GHSS JANDRAH",
  },
  {
    value: "461",
    label: "PRIMARY SCHOOL NAGOLA",
  },
  {
    value: "462",
    label: "Govt. Middle School Jhallar",
  },
  {
    value: "463",
    label: "PS JAKHYARI",
  },
  {
    value: "464",
    label: "HIGH SCHOOL BHATYARI ",
  },
  {
    value: "465",
    label: "MS KORGA",
  },
  {
    value: "466",
    label: "HIGH SCHOOL KISHANPUR KHARTA",
  },
  {
    value: "467",
    label: "GPS MANEH",
  },
  {
    value: "468",
    label: "BHSS KATHAR",
  },
  {
    value: "469",
    label: "GIRL MS KATHAR",
  },
  {
    value: "470",
    label: "GMS KATHAR",
  },
  {
    value: "471",
    label: "MS POUNTHAL",
  },
  {
    value: "472",
    label: "MIDDLE SCHOOL PATHWAR",
  },
  {
    value: "473",
    label: "BOYS HS SAGOON",
  },
  {
    value: "474",
    label: "PS BRAL",
  },
  {
    value: "475",
    label: "BOYS MS PANJOWA",
  },
  {
    value: "476",
    label: "Govt. HSS Surinsar",
  },
  {
    value: "477",
    label: "Govt. HSS Surinsar",
  },
  {
    value: "478",
    label: "MIDDLE SCHOOL BARGA",
  },
  {
    value: "479",
    label: "MIDDLE SCHOOL CHILLA",
  },
  {
    value: "480",
    label: " GOVT PRIMARY SCHOOL KULLIAN",
  },
  {
    value: "481",
    label: "BOYS HIGHER SECONDARY SCHOOL BHALWAL",
  },
  {
    value: "482",
    label: "MIDDLE SCHOOL KANGAR",
  },
  {
    value: "483",
    label: "BOYS MIDDLE SCHOOL KOT",
  },
  {
    value: "484",
    label: "MIDDLE SCHOOL KALA KAM KOT",
  },
  {
    value: "485",
    label: "PANCHAYAT GHAR MISRI WALA ",
  },
  {
    value: "486",
    label: " MIDDLE SCHOOL MISHRIWALA",
  },
  {
    value: "487",
    label: "BOYS  MIDDLE SCHOOL MISHRIWALA",
  },
  {
    value: "488",
    label: "GOVT HIGH SCHOOL PAKHIAN",
  },
  {
    value: "489",
    label: " GIRLS MIDDLE SCHOOL PURKHU",
  },
  {
    value: "490",
    label: " PANCHAYAT GHAR KOT GARHI",
  },
  {
    value: "491",
    label: "MIDDLE SCHOOL GHARI",
  },
  {
    value: "492",
    label: "HIGHER SECONDARY SCHOOL",
  },
  {
    value: "493",
    label: " BOYS HIGH SCHOOL RAI PUR",
  },
  {
    value: "494",
    label: "BOYS HIGH SCHOOL KHAIRI",
  },
  {
    value: "495",
    label: "MIDDLE SCHOOL KERAN",
  },
  {
    value: "496",
    label: "HIGH SCHOOL GURHA BRAHMA",
  },
  {
    value: "497",
    label: "MIDDLE SCHOOL THATHAR",
  },
  {
    value: "498",
    label: " BOYS PRIMARY SCHOOL MACHH",
  },
  {
    value: "499",
    label: "ABOYS PRIMARY SCHOOL PATOLI",
  },
  {
    value: "500",
    label: "PANCHAYAT GHAR PATOLI BRAH",
  },
  {
    value: "501",
    label: "GOVERNMENT MIDDLE SCHOOL ",
  },
  {
    value: "502",
    label: "GIRLS PRIMARY SCHOOL DARMAL",
  },
  {
    value: "503",
    label: " GOVT MIDDLE SCHOOL CHAK CHANGARWAN ",
  },
  {
    value: "504",
    label: " BOYS MIDDLE SCHOOL CHINORE",
  },
  {
    value: "505",
    label: "JDA COMPLEX ROOP NAGAR MAI",
  },
  {
    value: "506",
    label: " BOYS HIGHER SECONDARY SCHOOL ",
  },
  {
    value: "507",
    label: " TINY TOTS HIGHER SECONDARY",
  },
  {
    value: "508",
    label: "BOYS HIGHER SECONDARY SCHOOL",
  },
  {
    value: "509",
    label: "HIGH SCHOOL MUTHI",
  },
  {
    value: "510",
    label: "DruG Control Office Poloura ",
  },
  {
    value: "511",
    label: " NAVEEN VIDYA MANDIR NEAR POST",
  },
  {
    value: "512",
    label: "PRINCE PUBLIC SCHOOL  PALOURA",
  },
  {
    value: "513",
    label: " GIRLS MIDDLE SCHOOL PALOURA",
  },
  {
    value: "514",
    label: " MIDDLE SCHOOL MOHALLA GUJR",
  },
  {
    value: "515",
    label: "TINY TOTS HIGHER SECONDARY",
  },
  {
    value: "516",
    label: "RAINA HIGHER SECONDARY SCHOOL",
  },
  {
    value: "517",
    label: "GOVT HIGHER SECONDARY SCHOOL ",
  },
  {
    value: "518",
    label: "MIDDLE SCHOOL PATA PALOURA",
  },
  {
    value: "519",
    label: " Directorate of Economics & Statistics ",
  },
  {
    value: "520",
    label: " HANDICRAFT CENTER HOUSING",
  },
  {
    value: "521",
    label: "GOVT. GIRLS HIGH SCHOOL JANIPUR ",
  },
  {
    value: "522",
    label: " GOVT. GIRLS HIGH SCHOOL JANIPU",
  },
  {
    value: "523",
    label: " GOVT HIGH SCHOOL HOUSING COL. 1",
  },
  {
    value: "524",
    label: " Dream Land Public School Janipur12",
  },
  {
    value: "525",
    label: " Dream Land Public School Janipur",
  },
  {
    value: "526",
    label: " MIDDLE SCHOOL JANI PUR",
  },
  {
    value: "527",
    label: " BOYS MIDDLE SCHOOL JANI PUR",
  },
  {
    value: "528",
    label: " CENTRAL SCHOOL BUILDING PATTA CHUNGI",
  },
  {
    value: "529",
    label: "GIRLS MIDDLE SCHOOL PATOLI MANGOTRIAN ",
  },
  {
    value: "530",
    label: "GIRLS HIGH SCHOOL TOP SHER KHANIAN ",
  },
  {
    value: "531",
    label: "Govt primary school toph sherkhania ",
  },
  {
    value: "532",
    label: " PHE BUILDING BOHRI TLAB TILLO ",
  },
  {
    value: "533",
    label: "GIRLS HIGHER SECONDARY SCHOOL REHARI COLONY ",
  },
  {
    value: "534",
    label: "GIRLS HIGHER SECONDARY SCHOOL REHARI COLONY SOUTH SIDE ",
  },
  {
    value: "535",
    label: "GIRLS HIGHER SECONDARY SCHOOL REHARI COLONY NORTH SIDE ",
  },
  {
    value: "536",
    label: "BOARD OF SCHOOL BUILDING REHARI COLONY ROOM NO. 1 ",
  },
  {
    value: "537",
    label: " BOARD OF SCHOOL BUILDING REHARI COLONY ROOM NO. 4",
  },
  {
    value: "538",
    label: " BOARD OF SCHOOL BUILDING REHARI COLONY ROOM NO .2",
  },
  {
    value: "539",
    label: " BOARD OF SCHOOL BUILDING REHARI COLONY",
  },
  {
    value: "540",
    label: "BOARD OF SCHOOL BUILDING REHARI COLONY ROOM NO. 6 ",
  },
  {
    value: "541",
    label: "  GIRLS MIDDLE SCHOOL REHARI NAZDIK POLICE POST SARWAL",
  },
  {
    value: "542",
    label: "GOVERNMENT GIRLS HIGH SCHOOL RAJPURA MANGOTRIAN N BLOCK ",
  },
  {
    value: "543",
    label: "GOVERNMENT GIRLS HIGHER SCONDARY SCHOOL PRIMARY WING RAJPURA ",
  },
  {
    value: "544",
    label: "GOVERNMENT WOMEN POLYTECHNIC SHIV NAGAR ",
  },
  {
    value: "545",
    label: "ITI SHAKTI NAGAR NORTH ",
  },
  {
    value: "546",
    label: " ITI SHAKTI NAGAR SOUTHERN SIDE",
  },
  {
    value: "547",
    label: " Govt. Girls HiGh School Kabir Colony",
  },
  {
    value: "548",
    label: " Music & Fine Arts Academy Poonch House",
  },
  {
    value: "549",
    label: "MIDDLE SCHOOL GOLE PANJ PEER ",
  },
  {
    value: "550",
    label: " REVENUE TRAINING INSTITUTE COMPLEX GOLE GUJRAL JAMMU",
  },
  {
    value: "551",
    label: " GOVERNMENT PRIMARYSCHOOL MUNSHI CHAK",
  },
  {
    value: "551",
    label: " HSS Gujral ",
  },
  {
    value: "552",
    label: " GHS Gujral ",
  },
  {
    value: "553",
    label: " GPS POUNICHACK",
  },
  {
    value: "554",
    label: "  PANCHAYAT GHAR TRILOK PUR ",
  },
  {
    value: "555",
    label: " MIDDLE SCHOOL TRILOKPUR",
  },
  {
    value: "556",
    label: " AGRICULTURE FARM BUILDING TALAB TILLO",
  },
  {
    value: "557",
    label: "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL NAWABAD EASTERN SIDE ",
  },
  {
    value: "558",
    label: " GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL NAWABAD ",
  },
  {
    value: "559",
    label: "  GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL NAWABAD WEST SIDE",
  },
  {
    value: "560",
    label: " GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL NAWABAD NORTH",
  },
  {
    value: "561",
    label: " NEW SHASTRI MEMORIAL KIDZEE SCHOOL TALAB TILLO",
  },
  {
    value: "562",
    label: " GOVT BOYS HSS BAKSHI NAGAR",
  },
  {
    value: "563",
    label: "BOYS HIGHER SECONDARY SCHOOL BAKHSHI NAGAR SOUTH SIDE ",
  },
  {
    value: "564",
    label: "BOYS HIGHER SECONDARY SCHOOL BAKHSHI NAGAR WESTERN SIDE  ",
  },
  {
    value: "565",
    label: " SHIKSHA BHAWAN HIGH SCHOOL BAKHSHI NAGAR",
  },
  {
    value: "565",
    label: " BOYS HIGHER SECONDARY SCHOOL BAKHSHI NAGAR LEFT SIDE",
  },
  {
    value: "566",
    label: " Tej Public School,SardareChack",
  },
  {
    value: "567",
    label: " GIRLS PRIMARY SCHOOL KHIYARIAN",
  },
  {
    value: "568",
    label: " PRIMARY SCHOOL RANJEETPURA",
  },
  {
    value: "569",
    label: " SERICULTURE DEPARTMENT MEETING HALL RESHAM GARH",
  },
  {
    value: "570",
    label: " GOVT GIRLS HIGH SCHOOL RESHAM GHAR",
  },
  {
    value: "571",
    label: " OLD UNIVERSITY CAMPUS REC JAMMU",
  },
  {
    value: "572",
    label: " COMMERCE COLLEGE BUILDING JAMMU",
  },
  {
    value: "573",
    label: "  MIDDLE SCHOOL BELI AZMAT",
  },
  {
    value: "574",
    label: " BOYS MIDDLE SCHOOL CHAK KAHNA",
  },
  {
    value: "575",
    label: " GPS RAJPURA",
  },
  {
    value: "576",
    label: " GHS BALIYAL",
  },
  {
    value: "577",
    label: " GPS DUB SUDAIN",
  },
  {
    value: "578",
    label: " GMS DUB KARAMDIN",
  },
  {
    value: "579",
    label: " GOVT PRIMARY SCHOOL GURAH SINGHU",
  },
  {
    value: "580",
    label: "  GIRLS HIGH SCHOOL SHAMA CHAK",
  },
  {
    value: "581",
    label: " GOVT PRIMARY SCHOOL CHANNU CHAK",
  },
  {
    value: "582",
    label: "BOYS HIGHER SECONDARY SCHOOL JHIRI ",
  },
  {
    value: "583",
    label: "  GOVT PRIMARY SCHOOL BURJ KHADDIAN",
  },
  {
    value: "584",
    label: "  GOVT HIGH SCHOOL SIMBLI",
  },
  {
    value: "585",
    label: " PANCHAYAT GHAR GANGU CHAK",
  },
  {
    value: "586",
    label: " GIRLS PRIMARY SCHOOL NAI BASTI GAJANSOO",
  },
  {
    value: "587",
    label: " HSS GAJANSOO",
  },
  {
    value: "588",
    label: " GMS Deorey Nappu",
  },
  {
    value: "589",
    label: " GHS chatha Gujjran",
  },
  {
    value: "590",
    label: "  PS Jaswan",
  },
  {
    value: "591",
    label: " GPS Makhyal",
  },
  {
    value: "592",
    label: " GHS BURJ MANDIR",
  },
  {
    value: "593",
    label: " MS Chak Jaffer",
  },
  {
    value: "594",
    label: " Block Office Marh",
  },
  {
    value: "595",
    label: " Amar Jyoti Public High School Halqua",
  },
  {
    value: "596",
    label: " Panchayat Ghar Halqua",
  },
  {
    value: "597",
    label: " GHSS Marh",
  },
  {
    value: "598",
    label: " GHS Marh Bagh",
  },
  {
    value: "599",
    label: " GMS Galwadey Chak",
  },
  {
    value: "600",
    label: "BMS Dhateryal",
  },
  {
    value: "601",
    label: "GPS Bhulle Chak",
  },
  {
    value: "602",
    label: "GPS Nai Basti Gajansoo",
  },
  {
    value: "603",
    label: "HS Chargali",
  },
  {
    value: "604",
    label: "GPS KOTHEY MANHSAN",
  },
  {
    value: "605",
    label: " GMS Sohagini",
  },
  {
    value: "606",
    label: "GPS Lohri ChaK",
  },
  {
    value: "607",
    label: "PS Saharan",
  },
  {
    value: "608",
    label: "MS Rattanpur Gurdayal",
  },
  {
    value: "609",
    label: " PS Flora Haramkund",
  },
  {
    value: "610",
    label: "BMS Karloop",
  },
  {
    value: "611",
    label: "GPS Chak Chouhana",
  },
  {
    value: "612",
    label: "BMS Dhateryal",
  },
  {
    value: "613",
    label: "PANCHYAT GHAR FLORA NAGBANI",
  },
  {
    value: "614",
    label: "PS Flora ",
  },
  {
    value: "615",
    label: "PS LADORE ",
  },
  {
    value: "616",
    label: "Community Hall Flora Nagban",
  },
  {
    value: "617",
    label: "MS PARWAH",
  },
  {
    value: "618",
    label: "PANCHYAT GHAR FLORA NAGBANI",
  },
  {
    value: "619",
    label: "GPS Chak Chouhana",
  },
  {
    value: "620",
    label: "GPS Chak Chouhana",
  },
  {
    value: "621",
    label: "GHSS Gho Manhasan",
  },
  {
    value: "622",
    label: "GPS Bhagatpur",
  },
  {
    value: "623",
    label: "BHS Sandwan",
  },
  {
    value: "624",
    label: "BPS Raipur Jaggir",
  },
  {
    value: "625",
    label: "GPS Makwal ",
  },
  {
    value: "626",
    label: "Panchayat Ghar Makwal ",
  },
  {
    value: "627",
    label: "GMS Camp Makwal",
  },
  {
    value: "628",
    label: "PS Murarpur",
  },
  {
    value: "629",
    label: "BPS Prambali ",
  },
  {
    value: "630",
    label: "GMS BHADRORE",
  },
  {
    value: "631",
    label: "GPS Sarora ",
  },
  {
    value: "632",
    label: "MS Patniyal",
  },
  {
    value: "633",
    label: "GGHS Sangrampur",
  },
  {
    value: "634",
    label: "PS Shezadpur Pupa",
  },
  {
    value: "635",
    label: "HS Akalpur",
  },
  {
    value: "636",
    label: "GPS SARI RAKWALAN",
  },
  {
    value: "637",
    label: "GPS KHANPUR",
  },
  {
    value: "638",
    label: "GGHS Sohanjana ",
  },
  {
    value: "639",
    label: "GPS DEICHAK",
  },
  {
    value: "640",
    label: "COMMUNITY HALL CHAK LAL DIN",
  },
  {
    value: "641",
    label: "BPS Kirpalpur Charkan ",
  },
  {
    value: "642",
    label: "PS Karnala Chak",
  },
  {
    value: "643",
    label: "COMMUNITY HALL CHAK BHUPAT",
  },
  {
    value: "644",
    label: "GHSS Phallain",
  },
  {
    value: "645",
    label: "GMS Mandal",
  },
  {
    value: "646",
    label: "GMS SURECHAK",
  },
  {
    value: "647",
    label: "GPS MUKHRA",
  },
  {
    value: "648",
    label: "PS Sum",
  },
  {
    value: "649",
    label: "GHS Lacchmanpura",
  },
  {
    value: "650",
    label: "GOVT HIGH SCHOOL PANJGRAIN",
  },
  {
    value: "651",
    label: "GOVERNMENT MIDDLE SCHOOL BASAIRA",
  },
  {
    value: "652",
    label: "GOVERNMENTHR.SEC. SCHOOL MAWA BRAHMNAN",
  },
  {
    value: "653",
    label: "GOVERNMENT MIDDLE SCHOOL CHEERIAN",
  },
  {
    value: "654",
    label: "GOVERNMENT MIDDLE SCHOOL MANANI",
  },
  {
    value: "655",
    label: "HS PANGIARI GOPALA",
  },
  {
    value: "656",
    label: "PANCHAYAT GHAR PANGIARI GOPALA",
  },
  {
    value: "657",
    label: "GOVERNMENT PRIMARY SCHOOL KELEWALA",
  },
  {
    value: "658",
    label: "GOVT HIGH SCHOOL SUNGAL SUMAH",
  },
  {
    value: "659",
    label: "GOVERNMENT MIDDLE SCHOOL SUNGAL KHADWAN",
  },
  {
    value: "660",
    label: " GOVERNMENT HIGH SCHOOL SUNGAL",
  },
  {
    value: "661",
    label: "GOVT PRIMARY SCHOOL RAJA CHAK",
  },
  {
    value: "662",
    label: "HIGH SCHOOL LEHAR",
  },
  {
    value: "664",
    label: " MIDDLE SCHOOL NAI BASTI (THATI)",
  },
  {
    value: "665",
    label: "PANCHAYAT GHAR SUMAH",
  },
  {
    value: "666",
    label: "PANCHAYAT GHAR SUNGAL",
  },
  {
    value: "667",
    label: "GOVERNMENT HIGH SCHOOL KOTLI TANDA",
  },
  {
    value: "668",
    label: "GOVERNMENT MIDDLE SCHOOL KHUNGAN",
  },
  {
    value: "669",
    label: "GOVT.GIRLS MIDDLE SCHOOL KOTLI TANDA",
  },
  {
    value: "670",
    label: "GOVERNMENT PRIMARY SCHOOL BALGARA",
  },
  {
    value: "671",
    label: "GOVERNMENT GIRLS PRIMARY SCHOOL TARGAWAL",
  },
  {
    value: "672",
    label: "  GOVERNMENT PRY. SCHOOL JAKHARI",
  },
  {
    value: "673",
    label: " GOVERNMENT HIGH SCHOOL SUNAIL",
  },
  {
    value: "674",
    label: "GOVERNMENT MIDDLE SCHOOL AMBARAN",
  },
  {
    value: "675",
    label: "GOVERNMENT MIDDLE SCHOOL GURAH TALAB",
  },
  {
    value: "676",
    label: " GOVERNMENT MIDDLE SCHOOL GURAH TALAB",
  },
  {
    value: "677",
    label: "GOVERNMENT PRIMARY SCHOOL BHAMBERWAN",
  },
  {
    value: "678",
    label: "MIDDLE SCHOOL DASGA",
  },
  {
    value: "679",
    label: "GOVT MIDDLE SCHOOL BHARDA KALAN",
  },
  { value: "680", label: "GOVERNMENT MIDDLE SCHOOL GARH BHARDA" },
  { value: "680", label: "GOVERNMENT PRIMARY SCHOOL BARDHAL KALAN" },
  { value: "681", label: "MIDDLE SCHOOL PALWAN" },
  { value: "682", label: " GOVERNMENT MIDDLE SCHOOL BADGAL KALAN" },
  { value: "683", label: "GOVERNMENT MIDDLE SCHOOL JAMOTIAN" },
  { value: "684", label: "HIGH SCHOOL BHALWAL BRAHMNA" },
  { value: "685", label: "GOVERNMENT MIDDLE SCHOOL JADH" },
  { value: "686", label: "GOVERNMENT PRIMARY SCHOOL JADH SARDARAN" },
  { value: "687", label: "GOVT HIGH SCHOOL DHOK KHALSA" },
  { value: "688", label: "GOVT HIGH SCHOOL DHOK KHALSA" },
  { value: "689", label: "GOVT BOYS PRIMARY SCHOOL DHOK KHALSA" },
  { value: "690", label: "GOVERNMENT MIDDLE SCHOOL MALLEY JAD" },
  { value: "691", label: "GPS KANDYAL" },
  { value: "692", label: "PANCHAYAT GHAR RAJWAL" },
  { value: "693", label: "GOVT GIRLS MIDDLE SCHOOL CHAK BHAGWANA" },
  { value: "694", label: "COMMUNITY HALL POTHA COLONY" },
  { value: "695", label: "PRIMARY SCHOOL RATTANPUR" },
  { value: "696", label: "PRIMARY SCHOOL BHERI TARIAI" },
  { value: "697", label: " GOVERNMENT MIDDLE SCHOOL NAZLA CHAK" },
  { value: "698", label: "MIDDLE SCHOOL GURAH JAGIR " },
  { value: "699", label: " GOVT PRIMARY SCHOOL NAIWALA" },
  { value: "700", label: " GOVERNMENT GIRLS PRIMARY SCHOOL DEVI PUR" },
  { value: "701", label: "GOVERNMENT HSS SCHOOL SOHAL" },
  { value: "702", label: " GOVERNMENT MIDDLE SCHOOL SOHAL" },
  { value: "703", label: " GOVERNMENT PRIMARY SCHOOL SLEEPRI" },
  { value: "704", label: "GOVERNMENT PRIMARY SCHOOL NAMA KHU" },
  { value: "705", label: "GOVT HIGH SCHOOL KOTGARHI" },
  { value: "706", label: " PRIMARY SCHOOL MAWA KARORA" },
  { value: "707", label: " FLOOD CONTROL DIVISION OFFICE AKHNOOR" },
  { value: "708", label: " GIRLS MIDDLE SCHOOL GURHA BRAHMANA" },
  { value: "709", label: " GPS UPPER GURAH BRAHMANA" },
  { value: "710", label: "PANCHAYAT GHAR GURHA BRAHMANA WEST" },
  { value: "711", label: "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL AKHNOOR" },
  { value: "712", label: "RANGE OFFICE KALI DHAR AKHNOOR" },
  { value: "713", label: "GOVERNMENT GIRLS MIDDLE SCHOOL AKHNOOR" },
  { value: "714", label: "MC OFFICE AKHNOOR" },
  { value: "715", label: "GOVERNMENT PRIMARY SCHOOL SANGRAM PUR" },
  { value: "716", label: "GOVERNMENT MIDDLE SCHOOL CHAK BADYALAN" },
  { value: "717", label: "GOVERNMENTMIDDLE SCHOOL BAMAL" },
  { value: "718", label: "MIDDLE SCHOOL CHAK KIRPALPUR" },
  { value: "719", label: "GOVERNMENT PRIMARY SCHOOL MUTHI" },
  { value: "720", label: "GOVERNMENT PRIMARY SCHOOL MAIRA" },
  { value: "721", label: "GOVERNMENT MIDDLE SCHOOL MAIRA" },
  { value: "722", label: "PRIMARY SCHOOL MANDI WALA" },
  { value: "723", label: " GOVERNMENT MIDDLE SCHOOL BANDWAL" },
  { value: "724", label: "HIGHER SECONDARY SCHOOL RAKH DHOK JOURIAN" },
  { value: "725", label: "GIRLS HIGHER SECONDARY SCHOOL RAKH DHOK JOURIAN" },
  { value: "726", label: " MIDDLE SCHOOL MAAN CHAK" },
  { value: "727", label: "GPS CHAK SKINDAR" },
  { value: "728", label: "GPS RAKH KHAROON" },
  { value: "729", label: "GOVERNMENT HIGH SCHOOL GARKHAL" },
  { value: "730", label: "GMS N S PURA" },
  { value: "731", label: "HSS PARGWAL" },
  { value: "732", label: "GMS SAJWAL" },
  { value: "733", label: "HSS GURAH MANHASA" },
  { value: "734", label: "GMS HAMIRPUR" },
  { value: "735", label: "GPS SARWAL" },
  { value: "736", label: "GPS DEORA" },
  { value: "737", label: "GPS GOWAR" },
  { value: "738", label: "GPS RANGPUR" },
  { value: "739", label: "GMS BHALWAL MULLO" },
  { value: "740", label: "GMS BHALWAL BHARTH" },
  { value: "741", label: "GPS NAJWAL" },
  { value: "742", label: "GHS BELA JAMANA" },
  { value: "742", label: "GMS PINDI" },
  { value: "743", label: "GMS CHANNI TANA" },
  { value: "743", label: "GOVERNMENT BOYS MIDDLE SCHOOL SALIOTE" },
  { value: "744", label: "GOVERNMENT PRIMARY SCHOOL MAKARA" },
  { value: "745", label: "GHS RAH SALYOTE" },
  { value: "746", label: "HIGH SCHOOL PUKHARANI" },
  { value: "747", label: "GHS MALLAH" },
  { value: "748", label: "COMMUNITY HALL NEELI NALLAH" },
  { value: "749", label: "HIGH SCHOOL SERI PALLAI" },
  { value: "750", label: "PANCHAYAT GHAR BATTAL" },
  { value: "751", label: "GOVERNMENT HIGHER SECONDARY SCHOOL DHAKAR" },
  { value: "752", label: " GOVERNMENT HIGH SCHOOL JOGWAN" },
  { value: "753", label: "MIDDLE SCHOOL KAMDINI NALLA" },
  { value: "756", label: "GOVERNMENT HIGH SCHOOL NATHAL" },
  { value: "757", label: "MIDDLE SCHOOL LALE DE CHAPARA" },
  { value: "758", label: "HIGHER SECONDARY SCHOOL DORI DAGER" },
  { value: "759", label: "GHS PANYA" },
  { value: "760", label: "GOVERNMENT MIDDLE SCHOOL SANDAL" },
  { value: "761", label: "GOVERNMENT HIGH SCHOOL SALANGER" },
  { value: "762", label: "GOVERNMENT HIGH SCHOOL JHANGH" },
  { value: "763", label: " GOVERNMENT HIGH SCHOOL GHAR" },
  { value: "764", label: "GOVERNMENT MIDDLE SCHOOL MANAL BANGLA" },
  { value: "765", label: "GOVERNEMENT HIGH SCHOOL KANHERI" },
  { value: "766", label: "GOVERNMENT BOYS PRIMARY SCHOOL BUDHI CHARAI" },
  { value: "767", label: "GOVERNMENT PRIMARY SCHOOL RAMEEN MAKHIAN" },
  { value: "768", label: "GOVERNMENT PRIMARY SCHOOL JUTHAL" },
  { value: "769", label: "GOVERNMENT MIDDLE SCHOOL SALOR" },
  { value: "770", label: "GOVERNMENT PRIMARY SCHOOL GOUR BAJOURIAN" },
  { value: "771", label: " GOVERNMENT PRIMARY SCHOOL MEHARI" },
  { value: "772", label: " GOVERNMENT MIDDLE SCHOOL LATHERI" },
  { value: "773", label: "GOVERNMENT MIDDLE SCHOOL MANOH" },
  { value: "774", label: "HIGHER SECONDARY SCHOOL KATHAR" },
  { value: "775", label: " GOVERNMENT PRIMARY SCHOOL ARDHAAN" },
  { value: "776", label: "MS GODHAN" },
  { value: "777", label: " GOVERNMENT MIDDLE SCHOOL GHEUR" },
  { value: "778", label: "GOVERNMENT HIGH SCHOOL GANGAL" },
  { value: "779", label: "GOVERNMENT MIDDLE SCHOOL BASAWA" },
  { value: "780", label: " GOVERNMENT HIGHER SECONDARY SCHOOL CHOWKI CHOURA" },
  { value: "781", label: " GOVERNMENT BOYS PRIMARY SCHOOL BANDRAL KHURD" },
  { value: "782", label: "GOVERNMENT PRIMARY SCHOOL DANNA DHANNO" },
  { value: "783", label: "IGOVERNMENT BOYS MIDDLE SCHOOL DANA AMBU SHAHNI" },
  { value: "784", label: "PANCHAYAT GHAR GLABHA CHAPPAR DORI" },
  { value: "785", label: "MIDDLE SCHOOL BALL" },
  { value: "786", label: "PS KALAKAS" },
  { value: "787", label: "MS MAGERI" },
  { value: "788", label: "GOVERNMENT MIDDLE SCHOOL BARDOH" },
  { value: "789", label: "MIDDLE SCHOOL CHAPRIA" },
  { value: "790", label: "HS KHOUI MILLAN" },
  { value: "791", label: "GOVERNMENT MIDDLE SCHOOL KACHARIAL" },
  { value: "792", label: "GOVERNMENT HIGH SCHOOL KALAH" },
  { value: "793", label: "GOVERNMENT HIGHER SEC SCHOOL KHARAH" },
  { value: "794", label: "GOVERNMENT PRIMARY SCHOOL GAJUBAN" },
  { value: "795", label: "GOVT.MIDDLE SCHOOL SEHAR" },
  { value: "796", label: "GOVERNMENT MIDDLE SCHOOL THANDI CHOI" },
  { value: "797", label: "GOVERNEMENT PRIMARY SCHOOL GARGA" },
  { value: "798", label: "GOVERNMENT PRIMARY SCHOOL PARSOT" },
  { value: "799", label: "GOVERNMENT PRIMARY SCHOOL KANDI" },
  { value: "800", label: "GOVERNMENT MIDDLE SCHOOL BARUI" },
  { value: "801", label: "GOVERNMENT PRIMARY SCHOOL TACHARWANA" },
  { value: "802", label: "GOVERNMENT PRIMARY SCHOOL NALI" },
  { value: "803", label: " GOVERNMENT HIGH SECONDARY SCHOOL NARRARI BALA" },
  { value: "804", label: " HIGH SCHOOL GHARATTAL" },
  { value: "805", label: " GPS MADRIAN" },
  { value: "807", label: "GOVERNMENT MIDDLE SCHOOL GOSHAN " },
  { value: "808", label: " GOVERNMENT PRIMARY SCHOOL NORE JAGIR" },
  { value: "809", label: " GOVERNMENT PRIMARY SCHOOL MUNDH" },
  { value: "810", label: " GOVERNMENT PRIMARY SCHOOL RAHANI JAGOWAL" },
  { value: "811", label: " GOVERNMENT HIGH SCHOOL MERA MANDRIAN" },
  { value: "812", label: " GOVERNMENT PRIMARY SCHOOL PAYAN" },
  { value: "813", label: "  GOVERNMENT PRIMARY SCHOOL TARGHA" },
  { value: "814", label: " GOVERNMENT PRIMARY SCHOOL BAROLA" },
  { value: "815", label: " GOVERNMENT HIGH SCHOOL  KALEETH" },
  { value: "816", label: " GOVT MIDDLE SCHOOL DHANGER" },
  { value: "817", label: " MIDDLE SCHOOL DHALERI" },
  { value: "818", label: " HIGH SCHOOL NARYANA" },
  { value: "819", label: " PRIMARY SCHOOL UPPER NANDWA" },
  { value: "820", label: " MIDDLE SCHOOL PAHARI WALA" },
  { value: "821", label: " GOVERNMENT PRIMARY SCHOOL PANYALI " },
  { value: "822", label: " GPS PANYALI" },
  { value: "823", label: " GOVERNMENT HIGH SCHOOL KOT MAIRA" },
  { value: "824", label: " GOVERNMENT MIDDLE SCHOOL SAILAN WALI" },
  { value: "825", label: " GOVERNMENT HIGHERSECONDARY SCHOOL PALANWALA" },
  { value: "826", label: " WALA GOVERNMENT MIDDLE SCHOOL BANDOWALA" },
  { value: "827", label: " GOVERNMENT PRIMARY SCHOOL PANJTOOT" },
  { value: "828", label: " GOVERNMENT MIDDLE SCHOOL PLATAN" },
  { value: "829", label: "  PRIMARY SCHOOL CHANNI DEWANOO" },
  { value: "830", label: "  GOVERNMENT PRIMARY SCHOOL KHUNDA" },
  { value: "831", label: " GOVERNMENT MIDDLE SCHOOl " },
  { value: "832", label: " TUBE WELL BUILDING PADL" },
  { value: "833", label: " HIGHER SECONDARY SCHOOL KHOUR" },
  { value: "834", label: " GIRLS HIGH SCHOOL KHOUR" },
  { value: "835", label: " MIDDLE SCHOOL BANI BASTI" },
  { value: "836", label: " MIDDLE SCHOOL NIKKIAN" },
  { value: "837", label: " MS NIKKIAN" },
  { value: "838", label: " PRIMARY SCHOOL NIKKIAN" },
  { value: "839", label: " PANCHAYAT GHAR GIGRIAL" },
  { value: "840", label: " HS BUDWAL" },
  { value: "841", label: " GOVT HIGH SCHOOL GIGRIAL" },
  { value: "842", label: " GOVERNMENT MIDDLE CHOOL GARAR" },
  { value: "843", label: " PANCHAYAT GHAR SAINITH" },
  { value: "844", label: " GOVERNMENT HIGH SCHOOL HAMIRPUR SIDHAR" },
  { value: "845", label: " GOVERNMENT MIDDLE SCHOOL PANGALI" },
  { value: "846", label: " Middle school dehrian" },
  { value: "847", label: " MS DEHRIAN" },
  { value: "848", label: "GOVERNMENT MIDDLE SCHOOL CHAK RAMA " },
  { value: "849", label: " MIDDLE SCHOOL MATTU" },
  { value: "850", label: " GHS DATYAL" },
  { value: "851", label: " GMS BAKORE" },
  { value: "852", label: " GPS DHOUNCHAK" },
  { value: "853", label: " GMS BAKORE" },
  { value: "854", label: " GMS DADORA" },
  { value: "855", label: " GGNS MANCHAK" },
  { value: "856", label: " GPS GARHI BISHNA" },
  { value: "857", label: " GPS UPPER TAROTI" },
  { value: "858", label: " MS AIJAL MALAL" },
  { value: "859", label: " GMS RAKH MALAL" },
  { value: "860", label: "PANCHAYAT GHAR CHAK MALAL" },
  { value: "861", label: " MIDDLE SCHOOL DHANWAL CAMP" },
];

interface AssemblyOption {
  value: string; // Changed to string to accommodate the options consistently
  label: string;
}

export const assemblyOptions: AssemblyOption[] = [
  { value: "1", label: "Bishnah" },
  { value: "2", label: "Suchetgarh" },
  { value: "3", label: "R.S.Pura Jammu South" },
  { value: "4", label: "Bahu" },
  { value: "5", label: "Jammu East" },
  { value: "6", label: "Nagrota" },
  { value: "7", label: "Jammu West" },
  { value: "8", label: "Jammu North" },
  { value: "9", label: "Marh" },
  { value: "10", label: "Akhnoor" },
  { value: "11", label: "Chhamb" },
];
