// Footer.js
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <a href="https://corover.ai/" style={{ textDecoration: 'none' }}>
    <Box
      style={{
        padding: "5px 0px",
        textAlign: "center",
        position: "fixed",
        bottom: -25,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src="CoRover.png" width={70}alt="CoRover Logo" />
    </Box>
  </a>
  );
};

export default Footer;
