import React from "react";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Grid, Container, IconButton } from "@mui/material";
import { ErrorStyled, StyledDatePicker, VisuallyHiddenInput } from "./styled";
import { assemblyOptions, pollingOptions } from "../Data";
import { DeleteForever } from "@mui/icons-material";
import { addElectionDetails } from "../Api/apis";
import SuccessComponent from "./UI/successAnimation/Success";
import Loading from "./UI/loading";
import LocationAlert from "./UI/alert";
import { initialValues, toBase64, yupSchema } from "./Utils";
import CustomTextField from "./UI/textfield";
import CustomDisabledTextField from "./UI/disabledField";
import DropDownTextField from "./UI/dropDownTextfield";
import Footer from "./UI/footer";

const MyForm: React.FC = () => {
  const [imageLimit, setImageLimit] = React.useState(false);
  const [location, setLocation] = React.useState<number[]>([]);
  const [open, setOpen] = React.useState(false);
  const [alertStatus, SetAlertStatus] = React.useState(false);

  const getPosition = async (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const fetchLocation = async () => {
    try {
      const position = await getPosition();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLocation([latitude, longitude]);
    } catch (error) {
      console.error("Error getting location", error);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setOpen(true);
        const filesBase64 = await Promise.all(
          values.files.map((file: any) => toBase64(file))
        );
        const { files, ...rest } = values;
        const finalValues = {
          ...rest,
          filesBase64,
          lat: location[0],
          long: location[1],
        };
        const res = await addElectionDetails(finalValues);
        if (res.status) {
          formik.resetForm();
          setOpen(false);
          SetAlertStatus(res.status);
          setTimeout(() => {
            SetAlertStatus(false);
          }, 3000);
        }
      } catch (error) {
        console.error("Error converting files to base64:", error);
      }
      setSubmitting(false);
    },
  });

  React.useEffect(() => {
    fetchLocation();
    if (formik.values.files.length === 3) {
      setImageLimit(true);
    } else {
      setImageLimit(false);
    }
  }, [formik.values.files.length]);

  return (
    <React.Fragment>
      <Loading open={open} />
      {!alertStatus ? (
        <Container
          style={{
            margin: "auto",
            width: "100%",
            height: "calc(100vh - 20px)",
            overflowY: "auto",
            // padding: "0 16px",
          }}
        >
          <Grid item xs={12}>
            <span
              style={{
                fontSize: "25px",
                padding: "10px 0px 0px 0px ",
                color: "#1b5e20",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <b> Register a Plantation</b>
            </span>

            <LocationAlert location={location.length} />
          </Grid>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 20px 0px",
            }}
          >
            <Grid container spacing={1}>
              <DropDownTextField
                label="Polling Station Name"
                options={pollingOptions}
                value={formik.values.polingStation}
                error={formik.errors.polingStation}
                onChange={(newValue) =>
                  formik.setFieldValue("polingStation", newValue)
                }
              />

              <DropDownTextField
               label="Assembly Name"
                options={assemblyOptions}
                value={formik.values.Assembly}
                error={formik.errors.Assembly}
                onChange={(newValue) =>
                  formik.setFieldValue("Assembly", newValue)
                }
              />

              <Grid item xs={12}>
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          InputProps: {
                            style: {
                              height: "40px",
                            },
                          },
                        },
                      }}
                      label="Select Date"
                      value={
                        formik.values.selectedDate
                          ? dayjs(formik.values.selectedDate)
                          : null
                      }
                      onChange={(value) => {
                        // Handle date formatting
                        const formattedDate = value
                          ? value.format("YYYY/MM/DD")
                          : null;
                        formik.setFieldValue("selectedDate", formattedDate);
                      }}
                      maxDate={dayjs().startOf("day")}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  {formik.touched.selectedDate &&
                    formik.errors.selectedDate && (
                      <ErrorStyled>{formik.errors.selectedDate}</ErrorStyled>
                    )}
                </div>
              </Grid>

              <CustomTextField
                label="Forest Officer Name"
                name="forestOffName"
                value={formik.values.forestOffName}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.forestOffName && formik.errors.forestOffName
                }
                shrink={false}
              />

              <CustomTextField
                label="Designation"
                name="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.designation && formik.errors.designation
                }
                shrink={false}
              />

              <CustomTextField
                label="Mobile Number"
                name="mobileNum"
                value={formik.values.mobileNum}
                onChange={formik.handleChange}
                helperText={formik.touched.mobileNum && formik.errors.mobileNum}
                shrink={false}
              />

              <Grid item xs={12} style={{ display: "flex", gap: 20 }}>
                <CustomDisabledTextField
                  label="Lat."
                  name=""
                  disabled={true}
                  value={location[0]}
                  onChange={formik.handleChange}
                  helperText={""}
                  shrink={true}
                />

                <CustomDisabledTextField
                  label="Lang."
                  name=""
                  disabled={true}
                  value={location[1]}
                  onChange={formik.handleChange}
                  helperText={""}
                  shrink={true}
                />
              </Grid>

              <Grid item xs={12}>
                <div>
                  <Button
                    disabled={imageLimit}
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null;
                        if (file) {
                          if (formik.values.files.length < 3) {
                            formik.setFieldValue("files", [
                              ...formik.values.files,
                              file,
                            ]);
                          }
                        }
                      }}
                    />
                  </Button>
                  {formik.values.files.length > 0 && (
                    <ul>
                      {formik.values.files.map((file, index) => (
                        <li key={index}>
                          {file.name.slice(0, 12) +
                            "..." +
                            file.name.slice(file.name.lastIndexOf("."))}

                          <IconButton
                            onClick={() => {
                              const newFiles = formik.values.files.filter(
                                (_, i) => i !== index
                              );
                              formik.setFieldValue("files", newFiles);
                            }}
                          >
                            <DeleteForever style={{ color: "gray" }} />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  )}
                  {formik.touched.files && formik.errors.files && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.85rem",
                        margin: "3px",
                        padding: "0px 12px",
                      }}
                    >
                      {formik.values.files.length === 0 && "Upload image"}
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                marginTop: "5px",
                width: "100%",
                backgroundColor: "#2e7d32",
              }}
            >
              Submit
            </Button>
          </form>
          <Footer/>
        </Container>
      ) : (
        <SuccessComponent />
      )}
    </React.Fragment>
  );
};

export default MyForm;
