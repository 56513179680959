import React from "react";
import {
  Autocomplete,
  TextField,
  AutocompleteProps,
  TextFieldProps,
  Grid,
} from "@mui/material";

// Define the type for the option object
interface OptionType {
  label: string;
}

// Define the props for the DropDownTextField component
interface DropDownTextFieldProps {
  options: OptionType[];
  value: string;
  error?: string;
  onChange: (newValue: string) => void;
  // Extend TextFieldProps to allow passing additional props to TextField
  textFieldProps?: TextFieldProps;
  label: string;
}

const DropDownTextField: React.FC<DropDownTextFieldProps> = ({
  options,
  value,
  error,
  label,
  onChange,
  textFieldProps,
  ...rest
}) => {
  return (
    <Grid item xs={12}>
      <Autocomplete
        style={{ padding: "10px 0px 0px 0px" }}
        size="small"
        options={options}
        getOptionLabel={(option) => option.label.toUpperCase()}
        value={options.find((option) => option.label === value) || null}
        onChange={(
          event: React.SyntheticEvent,
          newValue: OptionType | null
        ) => {
          onChange(newValue ? newValue.label : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="filled"
            helperText={<span style={{ color: "#d32f2f" }}>{error}</span>}
            InputProps={{
              ...params.InputProps,
              style: {
                borderColor: "gray",
                height: "40px",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: "gray",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gray",
                },
              },
            }}
            {...textFieldProps} // Use textFieldProps to pass additional props
            {...rest}
          />
        )}
      />
    </Grid>
  );
};

export default DropDownTextField;
