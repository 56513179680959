import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import "./style.css";
// Define the keyframes for the animation
const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Define the styled components
const Container = styled("div")`
  margin: auto;
  width: 100%;
  height: calc(100vh - 20px);
  overflow-y: auto;
//   padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessMessage = styled("div")`
  animation: ${fadeInOut} 2s ease-in-out;
`;

const SuccessComponent: React.FC = () => (
  <Container style={{padding:"0px !important"}}>
    <SuccessMessage>
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
      <h1 style={{ color: "green" }}>Submitted</h1>
    </SuccessMessage>
  </Container>
);

export default SuccessComponent;
